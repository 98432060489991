<template>
    <div class="broadside">
        <div class="broadside_box">
            <div class="right_box">
                <div class="right_item" @mouseout="outPhone" @mouseover="overPhone">
                    <img src="../assets/side_phone.png">
                    <div class="phone_box" v-show="phoneShow">
                        <h3>邦芒全国服务热线</h3>
                        <h4>400-891-5050</h4>
                        <p>周一至周五 8：30-18：30</p>
                    </div>
                </div>
                <div class="right_item">
                    <img src="../assets/side_message.png" @click="faShow = !faShow">
                    <div class="message_box" v-if="faShow">
                        <div class="message_top">为您提供各地社保方案查询服务</div>
                        <div class="message_form_box">
                            <img src="../assets/address.png">
                            <el-select v-model="dataForm.sCode" placeholder="请选择省" @change="getQList"
                                style="width:120px;margin-right: 10px;">
                                <el-option v-for="item in options.sCityList" :key="item.code" :label="item.name"
                                    :value="item.code">
                                </el-option>
                            </el-select>
                            <el-select v-model="dataForm.qCode" placeholder="请选择市" ref="optionRef"
                                style="width:120px;margin-right: 10px;">
                                <el-option v-for="item in options.qCityList" :key="item.code" :label="item.name"
                                    :value="item.code">
                                </el-option>
                            </el-select>
                            <div class="submit" @click="submit">立即查询</div>
                        </div>
                        <div class="message_list_box">
                            <div class="message_list_title">
                                {{ qLabel }}
                            </div>
                            <div class="message_item_box">
                                <div class="message_item" v-for="(item, index) in options.faList" :key="index"
                                    @click="getTable(item)">
                                    {{ item.name }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="right_item">
                    <img src="../assets/side_top.png" @click="topping">
                </div>
            </div>
        </div>
        <broadsideDetail ref="broadsideDetail" v-if="broadsideDetailVisible"></broadsideDetail>
    </div>
</template>
<script>
import broadsideDetail from "./broadside-detail.vue"
import _service from "@/service";
export default {
    components: {
        broadsideDetail
    },
    data() {
        return {
            broadsideDetailVisible: false,
            phoneShow: false,
            faShow: false,
            qLabel: '',
            options: {
                sCityList: [],
                qCityList: [],
                faList: []
            },
            dataForm: {
                sCode: '',
                qCode: '',

            }

        }
    },
    mounted() {
        this.getSList()
    },
    methods: {
        topping() {
            window.scrollTo(0, 0);
        },
        openTc(data) {
            this.broadsideDetailVisible = true
            this.$nextTick(() => {
                this.$refs.broadsideDetail.data = data
                this.$refs.broadsideDetail.init()
            })
        },
        qSelectChange() {
            this.qLabel = this.$refs.optionRef.selected.currentLabel
        },
        overPhone() {
            // 移入
            console.log("overPhone")
            this.phoneShow = true
        },
        outPhone() {
            // 移出
            console.log("outPhone")
            this.phoneShow = false
        },
        //获取省
        getSList() {
            _service.getSList().then((res) => {
                this.options.sCityList = res;
            });
        },
        //获取市
        getQList() {
            this.dataForm.qCode = ''
            _service
                .getQList({ parentid: this.dataForm.sCode, deep: 2 })
                .then((res) => {
                    this.options.qCityList = res.list;
                });
        },
        // 获取社保方案id和名称
        submit() {
            this.qSelectChange()
            _service.getSocialSecurityList({ provinceCode: this.dataForm.sCode, cityCode: this.dataForm.qCode }).then((res) => {
                this.options.faList = res
            })
        },

        getTable(data) {


            _service.getSocialSecurityDetails({
                socialSecurityId: data.id,
                cityCode: data.cityCode,
                provinceCode: data.provinceCode
            }).then(res => {
                this.openTc(res)

            })
        },
    }
}
</script>
<style lang="scss" scoped>
.message_item_box {
    overflow-y: scroll;
    height: 80px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

}

.message_item {
    text-align: left;
    font-size: 14px;
    margin: 3px 0;
    width: 50%
}

.message_list_box {
    padding: 10px 20px;
}

.message_list_title {
    font-size: 18px;
    color: #d91416;
    font-weight: bold;
    text-align: left;
    margin-bottom: 10px;
}

.broadside_box {
    position: fixed;
    right: 0;
    top: 55%;
    z-index: 1000;
}

.right_item {
    position: relative;
    cursor: pointer;

    .phone_box {
        position: absolute;
        top: 0;
        right: 75px;
        width: 220px;
        background: #fff;
        color: #333;
        box-shadow: 0 0 5px #d0d0d0;
        line-height: 30px;
        text-align: left;
        padding: 30px 20px;
        box-sizing: border-box;

        h3 {
            font-size: 14px;
            margin: 0;
        }

        h4 {
            font-size: 22px;
            margin: 0;
        }

        p {
            font-size: 12px;
            margin: 0;
        }
    }

    .message_box {
        position: absolute;
        right: 75px;
        top: -50px;
        background: #fff;
        width: 395px;
        box-shadow: 0 0 5px #d0d0d0;

        .message_top {
            font-size: 14px;
            color: #fff;
            background: #d91416;
            text-align: left;
            box-sizing: border-box;
            padding: 5px 10px;
        }

        .message_form_box {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            padding: 12px 17px;

            img {
                width: 17px;
                height: 21px;
                margin-top: 5px;
                margin-right: 10px;
            }

            .submit {
                width: 70px;
                height: 30px;
                line-height: 30px;
                color: #fff;
                text-align: center;
                font-size: 12px;
                background: #D91416;
            }
        }
    }
}
</style>