<template>
    <div class="table_box" v-if="visible">
        <div class="table_body_box">
            <div class="close_box" @click="visible = false">×</div>
            <div class="table_title">{{data.name}}</div>
            <div class="table_html_box" v-html="table"></div>
        </div>

    </div>
</template>
<script>
export default {
    data() {
        return {
            visible: true,
            data: {},
            table: ''
        }
    },
    methods: {
        init() {
            this.visible = true
            console.log(this.data)
            this.pzTable()
        },
        aaa() {
            alert(111)
        },
        pzTable() {
            let str = ''
            console.log(this.data)
            this.data.items.forEach(item => {
                let strItem = `
                <tr>
                    <td>${item.type == 1 ? '养老' : item.type == 2 ? '医疗' : item.type == 3 ? '失业' : item.type == 4 ? '工伤' : item.type == 5 ? '生育' : '大病'}</td>
                    <td>${item.cardinalityMinU}</td>  
                    <td>${(item.scaleU * 100).toFixed(2)}%</td>    
                    <td>${item.minAmountU.toFixed(2)}</td>  
                    <td>${item.cardinalityMinP}</td>  
                    <td>${item.cardinalityMinP}</td>  
                    <td>${(item.scaleP * 100).toFixed(2)}%</td>
                    <td>${(item.scalePC * 100).toFixed(2)}%</td>
                    <td>${item.quotaP.toFixed(2)}</td>
                    <td>${item.minAmountP.toFixed(2)}</td>
                    <td>${item.minAmountPC.toFixed(2)}</td>
                    <td>${item.minAmount.toFixed(2)}</td>
                    <td>${(item.minAmountPC + item.minAmountU).toFixed(2)}</td>
                </tr>
                `
                str += strItem
            })

            let html = `
            <table border="1">
             <thead>
                <tr>
                    <th rowspan='2'>内容</th>
                     <th colspan='4'>单位</th>
                     <th colspan='6'>个人</th>
                    <th rowspan='2'>合计</th>
                 <th rowspan='2'>合计(农)</th>
                 </tr>
                 <tr>
                     <th rowspan='2'>最低基数</th>
                     <th rowspan='2'>比例(%)</th>
                     <th>定额</th>
                     <th>金额</th>
                     <th rowspan='2'>最低基数</th>
                     <th rowspan='2'>比例(%)</th>
                     <th rowspan='2'>比例(农)(%)</th>
                     <th rowspan='2'>定额</th>
                     <th>金额</th>
                     <th rowspan='2'>金额(农)</th>
                 </tr>
             </thead>
             <tbody>
                ${str}
             </tbody>
            </table>
             `
            this.table = html
        }

    }
}
</script>

<style lang="scss">
.close_box {
    position: absolute;
    right: 30px;
    top: 30px;
    cursor: pointer;
}

.table_title {
    padding-top: 30px;
    font-size: 24px;
    padding-bottom: 25px;
    font-weight: bold;
}

.table_box {
    table {
        border: #ccc;
        border-collapse: collapse;
        border-spacing: 0;
        font-size: 14px;

        thead {
            background: #efefef;
        }

        th {
            padding: 15px;
        }

        td {
            padding: 15px;
        }
    }

}
</style>

<style scoped lang="scss">
.table_box {
    z-index: 1000;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, .5);

    .close_box {
        position: absolute;
        right: 50px;
        top: 40px;
    }

    .table_body_box {
        background: #fff;
        position: absolute;
        left: 50%;
        margin-left: -30%;
        top: 30%;
        padding: 40px;
        box-sizing: border-box;
    }
}
</style>