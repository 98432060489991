const bmYears = 18;

var siteContents = {
 bmYears: bmYears, // 邦忙多少年了

 intro: [
  `邦芒人力 创建于2007年，中国服务业500强企业，总部位于上海。`,
  `公司主营服务外包、人事代理、人力资源SaaS服务、员工福利平台、产业园区运营、招商引资、财税代理等多航道业务，并积极探索互联网直聘平台等创新领域，以业务协作、资源交互的协同增效模式，打造一站式全产业链型企业。`,
  `公司现已实现全国核心市场布局，并积极开拓国际市场，目前有全球直营分公司228家，覆盖城市400余座，员工超过2600名，服务客户超3万家，服务雇员达100万。`,
 ],

 timeLine: [{
  year: 2024,
  list: [
   '“留 • 在浙里”香港引才工作站成立',
   `邦芒科技大厦启动`,
   `连续三年位列中国服务业500强`
  ]
 } , {
  year: 2023,
  list: [
   `国际事业中心成立`,
   `上海邦芒总部大楼启用`,
   `人力资源五星级企业`,
   `服务业龙头企业`,
  ]
 } , {
  year: 2022,
  list: [
   `国际事业中心成立`,
  ]
 }  , {
  year: 2021,
  list: [
   `全国直营公司突破228家`,
   `产业发展中心成立`,
  ]
 }  , {
  year: 2020,
  list: [
   `承接全国多个人力资源产业园`,
  ]
 }  , {
  year: 2018,
  list: [
   `北美设立首个海外服务中心`,
  ]
 }  , {
  year: 2017,
  list: [
   `全国布局32个省市自治区`,
   `荣获省级著名商标`,
  ]
 }  , {
  year: 2015,
  list: [
   `香港邦芒成立`,
  ]
 }  , {
  year: 2012,
  list: [
   `布局全国百城`,
   `北京、上海、广州、深圳邦芒陆续成立`,
  ]
 }  , {
  year: 2009,
  list: [
   `布局浙江全省`,
  ]
 } , {
  year: 2007,
  list: [
   `浙江嘉兴起航`,
  ]
 }    ],

 personalServiceContents: [{
  title: `入职管理`,
  desp: `入职手续办理、入职培训`
 }, {
  title: `合同档案管理`,
  desp: `合同签订、手续办理、档案建立`
 }, {
  title: `社保公积金管理`,
  desp: `社保公积金购买、待遇申领、证明出具、证件办理`
 }, {
  title: `商业保险管理`,
  desp: `商业保险方案定制、商保投保、商保理赔`
 }, {
  title: `薪税管理`,
  desp: `薪酬核算、工资发放、工资查询、个税申报`
 }, {
  title: `离职管理`,
  desp: `离职沟通、离职（自离、辞职、退回）手续办理`
 }, {
  title: `健康管理`,
  desp: `员工体检`
 }, {
  title: `员工福利`,
  desp: `年节福利、企业福利方案定制`
 }, ],

 personalServiceAdvantage: [
  `1.一地委托，全球服务，228城落地团队`,
  `2.597个直营社保/公积金账户，覆盖326个城市/地区`,
  `3.诺亚方舟智慧系统，高效协同`,
  `4.863服务体系，交付质量统一标准`,
  `5.自有律师团队，强控用工风险`,
  `6.减少人员投入，降低企业成本`,
 ],

 recruitService: [
  {
   title: `批量招聘服务`,
   desp: `批量招聘服务是一种面向基层岗位人才，在最短时间内找到最多、最合适人才的招聘方式，以满足企业招聘大量具备相似技能候选人的需求。`
  },
  {
   title: `猎聘服务`,
   desp: `针对专业从事中高端管理人才和技术人才的猎头业务，覆盖汽车能源、人工智能、互联网、金融科技、生产制造、物流供应链等行业。`
  },
  


 ],

 laborServiceAdvantage: [
   {
    title: `全球聘用`,
    desp: `为企业解决当地或全球范围内的用人招聘需求`,
   },
   {
    title: `全流程服务`,
    desp: `负责员工的合同签署、培训、薪资福利、社保缴纳等`,
   },
   {
    title: `合规安全`,
    desp: `资深专家团队，深谙当地政策法规，确保用工安全合规`,
   },
   {
    title: `专业咨询`,
    desp: `为企业提供当地政策法规及用工关系管理咨询`,
   },
 ],

 shortTermServiceAdvantage: [   {
  title: `用工方式灵活`,
  desp: `弹性工时、弹性薪酬、弹性人数`,
 },
 {
  title: `人员快速到岗`,
  desp: `强大的招聘网，快速匹配所需人才`,
 },
 {
  title: `有效风险隔离`,
  desp: `改变用工性质与用工关系`,
 },]
};

export { siteContents };
