<template>
  <div class="check" :class="type == 2 ? 'checkType2' : 'checkType3'">
    <div class="check_box">
      <div class="all_box">
        <div class="check_body">
          <div class="check_title">
            <h3>全国网点查询<span>联系邦芒</span></h3>
            <p>全国直营公司突破228家</p>
            <div class="henxian"></div>
          </div>
          {{ sCode }}
          <div class="check_select">
            <el-row>
              <el-col :span="6">
                <div class="select_item_box" style="margin-left: 0 !important">
                  <el-select
                    v-model="dataForm.sCode"
                    placeholder="省份"
                    filterable
                    style="width: 100%"
                    @change="getQList"
                  >
                    <el-option
                      v-for="item in options.sList"
                      :key="item.code"
                      :label="item.name"
                      :value="item.code"
                    >
                    </el-option>
                  </el-select>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="select_item_box">
                  <el-select
                    v-model="dataForm.qCode"
                    placeholder="城市"
                    filterable
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in options.qList"
                      :key="item.code"
                      :label="item.name"
                      :value="item.code"
                    >
                    </el-option>
                  </el-select>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="select_item_box">
                  <el-input
                    v-model="dataForm.compname"
                    placeholder="请输入内容"
                  ></el-input>
                </div>
              </el-col>
              <el-col :span="4">
                <div class="select_item_box">
                  <div class="wd_such" @click="getFgs">搜索网点</div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="check_list" v-if="type == 3">
          <div
            class="check_item"
            v-for="(item, index) in options.companyList"
            :key="index"
          >
            <div class="check_left">邦芒-{{ item.city }}</div>
            <div class="check_right">
              电话：{{ item.comptel }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;地址：{{ item.compaddr }}
            </div>
          </div>
        </div>

        <div class="message_box" v-if="type == 2">
          <message></message>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import message from "@/components/message.vue";
import _service from "@/service";
export default {
  components: {
    message,
  },
  props: {
    //2：其他页面，查询+留言板 3：contact页面，查询+查询列表
    type: {
      type: Number,
    },
    sCode: {
      type: Number,
    },
    qCode: {
      type: Number,
    },
    compname:{
      type:String
    }

  },
  watch:{
    sCode(val){
      this.dataForm.sCode = val
    },
    qCode(val){
      this.dataForm.qCode = val
      if(val){
        this.getFgs()
      }
     
    },
    compname(val){
      this.dataForm.compname = val
      if(val){
        this.getFgs()
      }
    }
  },

  data() {
    return {
      dataForm: {},
      options: {
        sList: [],
        qList: [],
        companyList: [],
      },
      messageData: {},
    };
  },
  mounted() {
    this.getSList();
    if(this.type == 3){
      this.getQList()

    }
    
  },

  methods: {
    //获取省
    getSList() {
      _service.getSList().then((res) => {
        this.options.sList = res;
      });
    },
    //获取市
    getQList() {
      debugger
      this.dataForm.qCode = ''
      _service
        .getQList({ parentid: this.dataForm.sCode, deep: 2 })
        .then((res) => {
          this.options.qList = res.list;
        });
    },
    //获取分公司
    getFgs() {
      if (this.type == 2) {
        console.log(this.dataForm);
        this.$emit("gotoContact", this.dataForm);
      }
      if (this.type == 3) {
        _service
          .getAllCompanyInfoPage({
            limit: 1000,
            page: 1,
            provincecode:this.dataForm.sCode,
            citycode: this.dataForm.qCode,
            compname: this.dataForm.compname
          })
          .then((res) => {
            this.options.companyList = res.list;
          });
      }
    },
  },
};
</script>

<style lang="scss">
  .check_select{
    .el-input__inner{
      color: #fff;
    }
  }
</style>

<style lang="scss" scoped>
// 联系我们页面
.checkType3 {
  .check_box {
    padding-bottom: 20px;
  }
}
// 其他页面
.checkType2 {
  .check_box {
    padding-bottom: 400px;
  }
}

.check_item {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 83.5%;
  margin: 25px 0;
  .check_left {
    background: #d70035;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    padding: 16px 25px;
  }
  .check_right {
    background: #fff;
    font-size: 16px;
    color: #333333;
    padding: 18px 25px;
    width: 885px;
    text-align: left;
  }
}
.check_box {
  position: relative;
  padding-top: 95px;
  padding-bottom: 400px;
  background: url(../../src/assets/check_bg.jpg) center center no-repeat;
  background-size: 100% 100%;
  .message_box {
    position: absolute;
    left: 50%;
    top: 280px;
    margin-left: -20.5%;
  }
  .check_title {
    text-align: left;
    h3 {
      font-size: 36px;
      color: #fff;
      margin: 0;
      margin-bottom: 20px;
      font-weight: lighter;
      span {
        font-size: 18px;
        color: #fff;
        border-left: 1px solid #fff;
        padding-left: 14px;
        margin-left: 14px;
      }
    }
    p {
      font-size: 16px;
      color: #fff;
      margin: 0;
      margin-bottom: 30px;
    }
    .henxian {
      width: 50px;
      height: 1px;
      background: #fff;
    }
  }
}
.message_box {
  background: #fdfcfc;
  border-radius: 10px;
  width: 45%;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 60px 80px;
}

.check_select {
  margin-top: 45px;
  .select_item_box {
    margin-left: 10px !important;
  }
}
.wd_such {
  color: #fff;
  font-size: 16px;
  background: #d70035;
  border-radius: 10px;
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
}
</style>
