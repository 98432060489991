//引入封装好的 service
import service from "@/service/service";

//声明一个基础接口变量
let baseURL;

//配置开发环境
if (process.env.NODE_ENV === 'development') {
  baseURL = 'https://www.50bm.com.cn';
}

// 配置生产环境
if (process.env.NODE_ENV === 'production') {
  baseURL = 'https://www.50bm.com.cn';
}

//设置请求头（如果请求头统一的话可以在axios文件设置，则无须从这里传过去）
const header = {
  Accept: 'application/json;charset=UTF-8',
}

//根据自身需求
let _service = {
  //获取省
  getSList(data) {
    const url =  `${baseURL}/attract_investment/front/show/getProvinceList`;
    return service.get(url, data, header);
  },
  //获取市
  getQList(data) {
    const url = `${baseURL}/attract_investment/front/show/getPlacetbPage`;
    return service.get(url, data, header);
  },
  //留言表单提交
  messageSubmit(data){
    const url = `${baseURL}/attract_investment/front/show/postSiteMsg`;
    return service.post(url, data, header);
  },
  //获取分公司
  getAllCompanyInfoPage(data){
    const url = `${baseURL}/attract_investment/front/show/getAllCompanyInfoPage`;
    return service.get(url, data, header)
  },

  //获取社保方案id和名称
  getSocialSecurityList(data){
    const url = `${baseURL}/attract_investment/front/show/getSocialSecurityList`
    return service.get(url, data, header)
  },

  //获取指定社保方案id的社保方案明细数据
  getSocialSecurityDetails(data){
    const url = `${baseURL}/attract_investment/front/show/getSocialSecurityDetails`
    return service.get(url, data, header)
  }
}


//导出
export default _service