<!-- 通用about -->
<template>
  <div class="aboutText">
    <div class="about_text_box">
  
      <p v-for="item in $config.intro" :key="item">{{ item }}</p>
    </div>
    <div class="about_number_list">
      <div class="about_number_item">
        <h4>228</h4>
        <p>全国设立228家直营公司</p>
      </div>
      <div class="about_number_item">
        <h4>400</h4>
        <p>业务辐射全国400+城市</p>
      </div>
      <div class="about_number_item">
        <h4>1000000+</h4>
        <p>累计为100多万雇员提供优质服务</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
    }
  }
}
</script>
<style lang="scss" scoped>

.about_text_box {
  font-size: 16px;
  color: #696969;
  line-height: 24px;
  text-align: left;
  text-indent: 2em;
  p {
    margin: 25px 0;
  }
}
.about_number_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  .about_number_item:first-child {
    margin-left: 0;
  }
  .about_number_item {
    margin-left: 140px;
    text-align: left;
    h4 {
      font-size: 24px;
      margin: 0;
      color: #b89369;
      margin-bottom: 12px;
    }
    p {
      font-size: 14px;
      margin: 0;
      color: #3f3a39;
    }
  }
}

</style>