import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/en',
    name: 'Home_en',
    component: () => import( '../views/Home_en.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import( '../views/Contact.vue')
  },
  {
    path: '/duty',
    name: 'Duty',
    component: () => import( '../views/Duty.vue')
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/terms',
    name: 'Terms',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Terms.vue')
  },
  {
    path:'/message',
    name:'Message',
    component: () => import( '../views/Message.vue')
  },
  {
    path:'/labour',
    name:'Labour',
    component: () => import( '../views/Work/Labour.vue')
  },
  {
    path:'/social',
    name:'Social',
    component: () => import( '../views/Work/Social.vue')
  },
  {
    path:'/scheme',
    name:'Scheme',
    component: () => import( '../views/Scheme.vue')
  },
  {
    path:'/pay',
    name:'Pay',
    component: () => import( '../views/Work/Pay.vue')
  },
  {
    path:'/human',
    name:'Human',
    component: () => import( '../views/Work/Human.vue')
  },{
    path:'/job',
    name:'Job',
    component: () => import( '../views/Work/Job.vue')
  },{
    path:'/userout',
    name:'Userout',
    component: () => import( '../views/Work/Userout.vue')
  },{
    path: '/:catchAll(.*)',
    redirect:'/'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
